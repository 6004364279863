// the below is suppressed because useForm requires spreading
/* eslint-disable react/jsx-props-no-spreading */
import {useForm} from 'react-hook-form';
import {useCallback, useEffect, useState} from 'react';
import {ActionStyledAsButton, Dialog, DialogActions, DialogContent, DialogHeader,} from '@ftrprf/tailwind-components';
import useFormatMessage from 'hooks/useFormatMessage';
import issuers from 'utils/constants/issuers';
import {dataTestIds} from 'utils/dataTestIds';
import {SmartschoolFields} from './fields/SmartschoolFields';
import {TeamsFields} from './fields/TeamsFields';
import {SharedFields} from './fields/SharedFields';

export function AddOrUpdateOrganizationDialog({
  alreadyExistPrefixes = false,
  dismissDialog,
  isAddOrganization = false,
  isOpen = false,
  organizationData,
  setOrganizationData,
  submitDialog,
}) {
  const t = useFormatMessage();
  const [issuer, setIssuer] = useState(null);
  // const { integrationInfo } = organizationData;
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      importMembershipOfStudentsToClasses:
        organizationData?.integrationInfo
          ?.importMembershipOfStudentsToClasses ?? true,
      importMembershipOfTeachersToClasses:
        organizationData?.integrationInfo
          ?.importMembershipOfTeachersToClasses ?? true,
      importStudents:
        organizationData?.integrationInfo?.importOneRosterStudents ?? true,
      importTeachers:
        organizationData?.integrationInfo?.importOneRosterTeachers ?? true,
      'oneRosterCredentials.clientId':
        organizationData?.integrationInfo?.oneRosterCredentials?.clientId ?? '',
      'oneRosterCredentials.clientSecret':
        organizationData?.integrationInfo?.oneRosterCredentials?.clientSecret ??
        '',
      showCalendar: organizationData?.showCalendar ?? true,
    },
  });

  const [showCalendarValue, domainName] = watch(['showCalendar', 'domainName']);
  const watchIssuer = watch('issuer');

  useEffect(() => {
    setIssuer(watchIssuer);
    if (organizationData?.issuer === issuers.SMARTSCHOOL) {
      setValue(
        'domainName',
        organizationData?.integrationInfo?.domainName ?? domainName,
      );
    }
    if (organizationData?.issuer === issuers.TEAMS) {
      setValue('tenantId', organizationData?.integrationInfo?.tenantId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationData, setValue, domainName, watchIssuer]);

  useEffect(() => {
    setIssuer(organizationData?.issuer);
  }, [organizationData?.issuer]);

  const onDismiss = useCallback(() => {
    dismissDialog();
    setIssuer(null);
    reset();
    if (organizationData) {
      setOrganizationData(null);
    }
  }, [dismissDialog, organizationData, reset, setOrganizationData]);

  const onSubmit = useCallback(
    (data) => {
      const {
        domainName,
        importMembershipOfStudentsToClasses,
        importMembershipOfTeachersToClasses,
        importStudents,
        importTeachers,
        issuer,
        language,
        name,
        oneRosterCredentials,
        prefix,
        showCalendar,
        tenantId,
      } = data;

      const temp = {
        issuer,
        language,
        name,
        prefix,
        showCalendar,
      };

      if (data.issuer === issuers.SMARTSCHOOL) {
        temp.smartSchoolInfo = {
          domainName,
          importMembershipOfStudentsToClasses,
          importMembershipOfTeachersToClasses,
          importOneRosterStudents: importStudents,
          importOneRosterTeachers: importTeachers,
          oneRosterCredentials,
        };

        if (oneRosterCredentials.clientSecret.includes('*')) {
          temp.smartSchoolInfo.oneRosterCredentials = null;
        }
      }

      if (data.issuer === issuers.TEAMS) {
        temp.teamsInfo = {
          tenantId,
        };
      }

      submitDialog(temp).then(() => {
        onDismiss();
      });
    },
    [onDismiss, submitDialog],
  );

  return (
    <Dialog isOpen={isOpen} onDismiss={onDismiss}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogHeader>
          {t(isAddOrganization ? 'aou-org.add' : 'aou-org.update')}
        </DialogHeader>
        <DialogContent>
          <div className="flex flex-col space-y-4">
            <SharedFields
              alreadyExistPrefixes={alreadyExistPrefixes}
              control={control}
              errors={errors}
              isAddOrganization={isAddOrganization}
              organizationData={organizationData}
              register={register}
              setIssuer={setIssuer}
              setValue={setValue}
              showCalendarValue={showCalendarValue}
            />

            {issuer === issuers.SMARTSCHOOL && (
              <SmartschoolFields
                errors={errors}
                issuer={issuer}
                register={register}
                setValue={setValue}
                watch={watch}
              />
            )}

            {issuer === issuers.TEAMS && (
              <TeamsFields
                errors={errors}
                issuer={issuer}
                register={register}
              />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <ActionStyledAsButton
            onClick={onDismiss}
            test={dataTestIds.modal.organization.button.cancel}
            variant="text"
          >
            {t('global.cancel')}
          </ActionStyledAsButton>
          <ActionStyledAsButton
            test={dataTestIds.modal.organization.button.submit}
            type="submit"
          >
            {t(
              isAddOrganization
                ? 'aou-org.submit'
                : 'update-program-dialog.confirm',
            )}
          </ActionStyledAsButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
